@import "../../../../../public/mixins/mixins.scss";

.storyCardWrapper {
  border-radius: 20px;
  border: 1px solid #e4e8ff;
  box-shadow: 0px 0px 6px 0px #dfdfdf;
  margin: 0px 10px;
  padding: 15px;
  @include flexContainer(column, unset, unset, 15px);

  width: 355px;

  @media (max-width: 465px) {
    width: 315px;
  }

  @media (max-width: 420px) {
    width: 265px;
  }
  .storyImgContainer {
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 185px;
  }

  .storyUserContainer {
    @include flexContainer(row, space-between);
    .storyBy {
      @include font(14px, 500, #4a536a, 0.56px);
    }
    .storyDate {
      @include font(12px, 500, #4a536a, 0.56px);
    }
  }

  .tagsContainer {
    @include flexContainer(row, unset, unset, 0px, 10px);
    .tagName {
      border-radius: 10px;
      background: #eff9ff;
      flex: 1 1 120px;
      text-align: center;
      padding: 12px 0px;
      @include font(11px, 500, #0078a3, 0.44px);
    }
  }

  .storyContainer {
    .storyHeading {
      @include font(14px, 500, #4a536a, 0.56px, 27px);
    }
    .storySubHeading {
      @include font(12px, 500, #666161, 0.56px, 27px);
    }
  }
}
